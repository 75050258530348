import type { SxProps, Theme as MuiTheme } from '@mui/material';
import { COMPONENT_SPACING, PALETTE } from '../common';

export type Theme = {
  root: SxProps<MuiTheme>;
  button: SxProps<MuiTheme>;
  title: SxProps<MuiTheme>;
  description: SxProps<MuiTheme>;
};

const theme: Theme = {
  root: {
    padding: '0px 20px 0px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  button: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    marginBottom: COMPONENT_SPACING,
    backgroundColor: PALETTE.secondary_dark,
    color: PALETTE.primary,
    padding: '15px',
    ':hover': {
      backgroundColor: PALETTE.secondary_dark,
    },
    ':disabled': {
      backgroundColor: PALETTE.secondary_dark,
      color: PALETTE.red,
    },
  },
  title: {
    fontWeight: 700,
    color: PALETTE.primary,
    fontSize: '26px',
  },
  description: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    color: PALETTE.primary,
    marginBottom: COMPONENT_SPACING,
    fontSize: '20px',
  },
};

export default theme;
