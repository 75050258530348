import type { SxProps, Theme as MuiTheme } from '@mui/material';
import { COMPONENT_SPACING, PALETTE } from '../common';

export type Theme = {
  root: SxProps<MuiTheme>;
  form: SxProps<MuiTheme>;
  title: SxProps<MuiTheme>;
  description: SxProps<MuiTheme>;
  fields: {
    inputEmail: SxProps<MuiTheme>;
    inputCode: SxProps<MuiTheme>;
    inputCodeInfo: SxProps<MuiTheme>;
    inputPassword: SxProps<MuiTheme>;
    inputConfirmPassword: SxProps<MuiTheme>;
  };
  ctaSendCode: SxProps<MuiTheme>;
  ctaSubmit: SxProps<MuiTheme>;
};

const theme: Theme = {
  root: {},
  title: {
    padding: '0px 16px 0px 16px',
    fontSize: '26px',
    fontWeight: 700,
    color: 'white',
  },
  description: {
    fontFamily: 'Manrope',
    padding: '0px 16px 20px 16px',
    fontSize: '14px',
    color: 'white',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '1rem',
    paddingTop: 0,
  },
  fields: {
    inputEmail: {
      fontFamily: 'Manrope',
      marginTop: COMPONENT_SPACING,
      backgroundColor: 'white',
      borderRadius: '80px', // Set your desired border-radius
      padding: '0px 20px 0px 20px',
    },
    inputCode: {
      fontFamily: 'Manrope',
      marginTop: COMPONENT_SPACING,
      backgroundColor: 'white',
      borderRadius: '80px', // Set your desired border-radius
      padding: '0px 20px 0px 20px',
    },
    inputCodeInfo: {
      fontFamily: 'Manrope',
      padding: '2px',
      color: 'white',
      fontSize: '14px',
      lineHeight: '18px',
      'a[href^="mailto:"]': {
        color: PALETTE.secondary_dark,
      },
    },
    inputPassword: {
      fontFamily: 'Manrope',
      marginTop: COMPONENT_SPACING,
      backgroundColor: 'white',
      borderRadius: '80px', // Set your desired border-radius
      padding: '0px 20px 0px 20px',
    },
    inputConfirmPassword: {
      fontFamily: 'Manrope',
      marginTop: COMPONENT_SPACING,
      backgroundColor: 'white',
      borderRadius: '80px', // Set your desired border-radius
      padding: '0px 20px 0px 20px',
    },
  },
  ctaSendCode: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    marginTop: COMPONENT_SPACING,
    backgroundColor: PALETTE.secondary_dark,
    color: PALETTE.primary,
    ':hover': {
      backgroundColor: PALETTE.secondary_dark,
    },
    ':disabled': {
      backgroundColor: PALETTE.secondary_dark,
      color: PALETTE.red,
    },
  },
  ctaSubmit: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    marginTop: COMPONENT_SPACING,
    backgroundColor: PALETTE.secondary_dark,
    color: PALETTE.primary,
    ':hover': {
      backgroundColor: PALETTE.secondary_dark,
    },
    ':disabled': {
      backgroundColor: PALETTE.secondary_dark,
      color: PALETTE.red,
    },
  },
};

export default theme;
