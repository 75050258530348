import { useEffect } from 'react';
import {
  Box, Button, useTheme,
} from '@mui/material';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Screen from '../../components/Screen';
import { FormatMessage } from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import * as auth from '../../store/actions/auth';
import { registerUserSchema, RegisterUserType } from '../../schemas/registerUser';
import FormInput from '../../components/Form/Input';
import PhoneNumberInput from '../../components/Form/PhoneInput/PhoneNumberInput';
import FormSelect from '../../components/Form/FormSelect';
import ErrorAlert from '../../components/ErrorAlert';
import InfoAlert from '../../components/InfoAlert';
import Loading from '../../components/Loading';

const Register = () => {
  const registerUserState = useAppSelector((state) => state.auth.registerUser);
  const clinicians = useAppSelector((state) => state.auth.clinicians);

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm<RegisterUserType>({
    resolver: yupResolver(registerUserSchema),
  });

  useEffect(() => {
    // getClinicians list only if not yet available
    if (clinicians.length < 1) {
      dispatch(auth.getClinicians());
    }
  }, [dispatch, clinicians]);

  const onSubmit: SubmitHandler<RegisterUserType> = (data) => {
    dispatch(auth.registerUser({
      ...data,
      dateOfBirth: data.dateOfBirth.toDateString(),
    }));
  };

  return (
    <Screen className="registration" gaName="register" canNavBack>
      <Box sx={theme.screens.registerUser.title}>
        <FormatMessage id="registerUser.title" />
      </Box>
      <Box component="form" sx={theme.screens.registerUser.form} onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          name="First Name"
          inputProps={{
            ...register('firstName'),
            sx: theme.screens.registerUser.fields.main,
            type: 'text',
          }}
          error={errors.firstName?.message}
          placeholderId="registerUser.firstNamePlaceholder"
        />
        <FormInput
          name="Last Name"
          inputProps={{
            ...register('lastName'),
            sx: theme.screens.registerUser.fields.main,
            type: 'text',
          }}
          error={errors.lastName?.message}
          placeholderId="registerUser.lastNamePlaceholder"
        />

        <FormInput
          name="Date of Birth (MM/DD/YYYY)"
          inputProps={{
            ...register('dateOfBirth'),
            sx: theme.screens.registerUser.fields.main,
            type: 'date',
          }}
          error={errors.dateOfBirth?.message && 'Invalid Date'}
          placeholderId="registerUser.dateOfBirthPlaceholder"
        />

        <FormInput
          name="Email Address"
          inputProps={{
            ...register('email'),
            sx: theme.screens.registerUser.fields.main,
            type: 'email',
          }}
          error={errors.email?.message}
          placeholderId="registerUser.emailPlaceholder"
        />

        <FormInput
          error={errors.phoneNumber?.message}
          name="Phone Number"
          inputProps={{
            ...register('phoneNumber'),
            sx: theme.screens.registerUser.fields.main,
            type: 'phoneNumber',
          }}
          input={(
            <Controller
              name="phoneNumber"
              control={control}
              render={({
                field: {
                  onChange, onBlur, value, name,
                },
              }) => (
                <PhoneNumberInput
                  countries={['us']}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  // ref={ref}
                  disableDropdown
                  inputProps={{
                    name,
                  }}
                />
              )}
            />
          )}
        />
        <FormInput
          name="Password"
          inputProps={{
            ...register('password'),
            sx: theme.screens.registerUser.fields.main,
            type: 'password',
          }}
          error={errors.password?.message}
          placeholderId="registerUser.passwordPlaceholder"
          tooltipId="registerUser.passwordTooltip"
        />
        <FormInput
          name="Retype Password"
          inputProps={{
            ...register('confirmPassword'),
            sx: theme.screens.registerUser.fields.main,
            type: 'password',
          }}
          error={errors.confirmPassword?.message}
          placeholderId="registerUser.confirmPasswordPlaceholder"
        />
        <FormSelect
          name="OB/GYN Doctor"
          inputProps={{
            ...register('doctor'),
            sx: theme.screens.registerUser.fields.inputDoctor,
            type: 'select',
          }}
          error={errors.doctor?.message}
          placeholderId="registerUser.doctorPlaceholder"
          items={clinicians}
          disabled={registerUserState.loading}
        />
        {registerUserState?.error && <ErrorAlert message={registerUserState?.error} />}
        {registerUserState?.info && <InfoAlert message={registerUserState?.info} />}
        <Button
          variant="contained"
          sx={theme.screens.registerUser.ctaSubmit}
          type="submit"
          disabled={registerUserState.loading}
        >
          {registerUserState.loading ? (
            <Box style={{ display: 'flex' }}>
              <Loading />
              <FormatMessage id="registerUser.ctaSubmitLoading" />
            </Box>
          )
            : (
              <FormatMessage id="registerUser.ctaSubmit" />
            )}
        </Button>

      </Box>
    </Screen>
  );
};

export default Register;
