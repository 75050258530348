import React, { useMemo } from 'react';
// import InputProps from '@mui/material/Select';
import {
  MenuItem,
  FormControl,
  SxProps,
  Select,
} from '@mui/material';
import { useIntl } from 'react-intl';
import HelperText from '../HelperText';
import Label from '../FormLabel';

export type Clinician = {
  id: string;
  name: string;
  email: string;
};

export type Props = {
  name: String;
  inputProps: any;
  items: Clinician[];
  sx?: SxProps;
  error?: React.ReactNode;
  // i18n id for the placeholder and tooltip
  placeholderId?: string;
  disabled: boolean;
};

const FormSelect: React.FC<Props> = ({
  name,
  inputProps,
  items,
  sx,
  error,
  placeholderId,
  disabled,
}) => {
  const intl = useIntl();
  const placeholderText = useMemo(
    () => placeholderId && intl.formatMessage({ id: placeholderId }),
    [placeholderId],
  );

  return (
    <FormControl fullWidth sx={sx}>
      <Label focused={false}>
        {name}
      </Label>
      <Select
        // eslint-disable-next-line
        {...inputProps}
        error={!!error}
        placeholder={placeholderText}
        style={{ marginTop: '15px' }}
        disabled={disabled}
      >
        {items.map((item: Clinician) => (
          <MenuItem key={item.id} value={item.email}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <HelperText sx={{}}>
          {error}
        </HelperText>
      )}
    </FormControl>
  );
};

export default FormSelect;
