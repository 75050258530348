import React from 'react';
import {
  Box, Button, useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { useForm, SubmitHandler } from 'react-hook-form';
import Screen from '../../components/Screen';
import { FormatMessage } from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import * as auth from '../../store/actions/auth';
import { VerifyCodeGuard, VerifyCodeType } from '../../guards/verifyCode';
import FormInput from '../../components/Form/Input';
import logger from '../../utils/logger';
import ErrorAlert from '../../components/ErrorAlert';
import Loading from '../../components/Loading';

const VerifyCode = () => {
  const verifyCodeState = useAppSelector((state) => state.auth.verifyCode);
  const email = useAppSelector((state) => state.auth.email);
  const resendCodeState = useAppSelector((state) => state.auth.resendCode);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const intl = useIntl();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<VerifyCodeType>({
    resolver: superstructResolver(VerifyCodeGuard),
  });
  const txtPlaceholder = React.useMemo(() => intl.formatMessage({
    id: 'verifyCode.otpPlaceholder',
  }), []);

  const onSubmit: SubmitHandler<VerifyCodeType> = (data) => {
    dispatch(auth.verifyCode({ email, code: data.code }));
  };

  const handle2FAResend = () => {
    dispatch(auth.resend2FACode({ email }));
  };

  logger.debug({ errors });

  return (
    <Screen className="verify-code" gaName="verify-code" canNavBack>
      <Box sx={theme.screens.verifyCode.title}>
        <FormatMessage id="verifyCode.title" />
      </Box>
      <Box sx={theme.screens.verifyCode.description}>
        <FormatMessage id="verifyCode.description" />
      </Box>
      <Box component="form" sx={theme.screens.verifyCode.form} onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          name="Code"
          inputProps={{
            ...register('code'),
            sx: theme.screens.verifyCode.inputOtp,
            placeholder: txtPlaceholder,
          }}
          error={errors.code?.message}
        />
        {verifyCodeState?.error && <ErrorAlert message={verifyCodeState?.error} />}
        <Button
          variant="contained"
          sx={theme.screens.verifyCode.ctaSubmit}
          type="submit"
          disabled={verifyCodeState.loading || resendCodeState.loading}
        >
          {verifyCodeState.loading || resendCodeState.loading ? (
            <Box style={{ display: 'flex' }}>
              <Loading />
              <FormatMessage id="verifyCode.ctaVerifingCode" />
            </Box>
          )
            : (
              <FormatMessage id="verifyCode.ctaSubmit" />
            )}
        </Button>
      </Box>
      <Box sx={theme.screens.verifyCode.lowerBox}>
        <Button
          onClick={handle2FAResend}
          disabled={resendCodeState.loading || verifyCodeState.loading}
          sx={theme.screens.verifyCode.ctaResendCode}
        >
          <FormatMessage id="verifyCode.ctaResendCode" />
        </Button>
        <Box>
          <FormatMessage id="verifyCode.contactSupport" />
        </Box>
      </Box>
    </Screen>
  );
};

export default VerifyCode;
