import type { SxProps, Theme as MuiTheme } from '@mui/material';
import { COMPONENT_SPACING, PALETTE } from '../common';

export type Theme = {
  root: SxProps<MuiTheme>;
  title: SxProps<MuiTheme>;
  form: SxProps<MuiTheme>;
  fields: {
    main: SxProps<MuiTheme>;
    inputFirstName: SxProps<MuiTheme>;
    inputLastName: SxProps<MuiTheme>;
    inputDateOfBirth: SxProps<MuiTheme>;
    inputEmail: SxProps<MuiTheme>;
    inputPhoneNumber: SxProps<MuiTheme>;
    inputPassword: SxProps<MuiTheme>;
    inputConfirmPassword: SxProps<MuiTheme>;
    inputDoctor: SxProps<MuiTheme>;
  };
  ctaSubmit: SxProps<MuiTheme>;
};

const theme: Theme = {
  root: {},
  title: {
    fontFamily: 'Manrope',
    padding: '0px 20px 0px 20px',
    fontSize: '26px',
    fontWeight: 700,
    color: 'white',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '1rem',
    overflow: 'auto',
  },
  fields: {
    main: {
      fontFamily: 'Manrope',
      marginTop: COMPONENT_SPACING,
      backgroundColor: 'white',
      borderRadius: '80px',
      padding: '0px 20px 0px 20px',
      color: '#000000',
    },
    inputFirstName: {},
    inputLastName: {},
    inputDateOfBirth: {},
    inputEmail: {},
    inputPhoneNumber: {},
    inputPassword: {},
    inputConfirmPassword: {},
    inputDoctor: {
      fontFamily: 'Manrope',
      backgroundColor: 'white',
      borderRadius: '80px',
      padding: '0px 20px 0px 20px',
      color: '#000000',
      '.MuiSelect-select': {
        padding: '5px',
      },
    },
  },
  ctaSubmit: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    marginTop: COMPONENT_SPACING,
    backgroundColor: PALETTE.secondary_dark,
    color: PALETTE.primary,
    ':hover': {
      backgroundColor: PALETTE.secondary_dark,
    },
    ':disabled': {
      backgroundColor: PALETTE.secondary_dark,
      color: PALETTE.red,
    },
  },
};

export default theme;
