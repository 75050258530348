export const INITIATE_LOGIN_LOADING = 'INITIATE_LOGIN_LOADING';
export const INITIATE_LOGIN_SUCCESS = 'INITIATE_LOGIN_SUCCESS';
export const INITIATE_LOGIN_FAIL = 'INITIATE_LOGIN_FAIL';

export const VERIFY_CODE_LOADING = 'VERIFY_CODE_LOADING';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_FAIL = 'VERIFY_CODE_FAIL';

export const RESEND_CODE_LOADING = 'RESEND_CODE_LOADING';
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';
export const RESEND_CODE_FAIL = 'RESEND_CODE_FAIL';

export const REGISTER_USER_LOADING = 'REGISTER_USER_LOADING';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const FOR_PASS_SEND_CODE_LOADING = 'FOR_PASS_SEND_CODE_LOADING';
export const FOR_PASS_SEND_CODE_SUCCESS = 'FOR_PASS_SEND_CODE_SUCCESS';
export const FOR_PASS_SEND_CODE_FAIL = 'FOR_PASS_SEND_CODE_FAIL';

export const FOR_PASS_CHANGE_PASS_LOADING = 'FOR_PASS_CHANGE_PASS_LOADING';
export const FOR_PASS_CHANGE_PASS_SUCCESS = 'FOR_PASS_CHANGE_PASS_SUCCESS';
export const FOR_PASS_CHANGE_PASS_FAIL = 'FOR_PASS_CHANGE_PASS_FAIL';

export const LOG_OUT_LOADING = 'LOG_OUT_LOADING';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAIL = 'LOG_OUT_FAIL';

export const SET_EMAIL = 'SET_EMAIL';
export const SET_EXTERNAL_USER_ID = 'SET_EXTERNAL_USER_ID';
export const RESET_STATE = 'RESET_STATE';
export const SET_LOGIN_INFO_MESSAGE = 'SET_LOGIN_INFO_MESSAGE';

export const ACCEPT_CONSENT_LOADING = 'ACCEPT_CONSENT_LOADING';
export const ACCEPT_CONSENT_SUCCESS = 'ACCEPT_CONSENT_SUCCESS';
export const ACCEPT_CONSENT_FAIL = 'ACCEPT_CONSENT_FAIL';

export const GET_CLINICIANS_LOADING = 'GET_CLINICIANS_LOADING';
export const GET_CLINICIANS_FAIL = 'GET_CLINICIANS_FAIL';
export const GET_CLINICIANS_SUCCESS = 'GET_CLINICIANS_SUCCESS';
