import {
  is, object, Infer, define,
} from 'superstruct';

const OTPGuard = define<string>('OTP', (v: unknown) => {
  if (!v) {
    return {
      message: 'Code is required. Please check your email.',
    };
  }
  if (`${v}`.length < 6 || `${v}`.length > 6) {
    return {
      message: 'Invalid code. Please check your email.',
    };
  }

  return true;
});

export const VerifyCodeGuard = object({
  code: OTPGuard,
});

export const isValid = (arg: unknown) => is(arg, VerifyCodeGuard);

export type VerifyCodeType = Infer<typeof VerifyCodeGuard>;
