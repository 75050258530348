import React from 'react';
import {
  Box, Button, useTheme,
} from '@mui/material';
import Screen from '../../components/Screen';
import { FormatMessage } from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import logger from '../../utils/logger';
import { NAV_TO } from '../../store/types/nav';
import NavScreen from '../../enums/navScreen';

const MainMenu = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const userFirstName = useAppSelector((state) => state.auth.userFirstName);
  logger.log(theme, dispatch);

  const navToMoodWordWidget = () => {
    dispatch({
      type: NAV_TO,
      payload: NavScreen.voiceWidget,
    });
  };

  /* const navToWellnessWidget = () => {
    dispatch({
      type: NAV_TO,
      payload: NavScreen.wellnessWidget,
    });
  }; */

  return (
    <Screen className="main-menu" gaName="main-menu" canLogout>
      <Box sx={theme.screens.mainMenu.root}>
        <Box style={{ fontSize: '26px' }}>
          👋
        </Box>
        <Box sx={theme.screens.mainMenu.title}>
          <FormatMessage id="mainMenu.title" values={{ userFirstName }} />
        </Box>
        <Box sx={theme.screens.mainMenu.description}>
          <FormatMessage id="mainMenu.description" />
        </Box>
        <Button
          sx={theme.screens.mainMenu.button}
          variant="contained"
          onClick={navToMoodWordWidget}
        >
          <FormatMessage id="mainMenu.moodWordWidgetLink" />
        </Button>
        { /* <Button
          sx={theme.screens.mainMenu.button}
          variant="contained"
          onClick={navToWellnessWidget}
        >
          <FormatMessage id="mainMenu.wellnessWidgetLink" />
  </Button> */}
      </Box>
    </Screen>
  );
};

export default MainMenu;
