import type { SxProps, Theme as MuiTheme } from '@mui/material';
import { COMPONENT_SPACING, PALETTE } from '../common';

export type Theme = {
  root: SxProps<MuiTheme>;
  title: SxProps<MuiTheme>;
  description: SxProps<MuiTheme>;
  form: SxProps<MuiTheme>;
  inputOtp: SxProps<MuiTheme>;
  lowerBox: SxProps<MuiTheme>;
  ctaSubmit: SxProps<MuiTheme>;
  ctaRegister: SxProps<MuiTheme>;
  ctaResendCode: SxProps<MuiTheme>;
};

const theme: Theme = {
  root: {},
  title: {
    padding: '0px 16px 0px 16px',
    fontSize: '26px',
    fontWeight: 700,
    color: 'white',
  },
  description: {
    fontFamily: 'Manrope',
    padding: '0px 16px 20px 16px',
    fontSize: '14px',
    color: 'white',
  },
  form: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  inputOtp: {
    fontFamily: 'Manrope',
    marginTop: COMPONENT_SPACING,
    backgroundColor: 'white',
    borderRadius: '80px', // Set your desired border-radius
    padding: '0px 20px 0px 20px',
  },
  lowerBox: {
    fontFamily: 'Manrope',
    display: 'flex',
    padding: '0px 20px 0px 20px',
    flexDirection: 'column',
    justifyContent: 'space-around',
    color: 'white',
    fontSize: '14px',
    lineHeight: '18px',
    'a[href^="mailto:"]': {
      color: PALETTE.secondary_dark,
    },
  },
  ctaSubmit: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    marginTop: COMPONENT_SPACING,
    backgroundColor: PALETTE.secondary_dark,
    color: PALETTE.primary,
    ':hover': {
      backgroundColor: PALETTE.secondary_dark,
    },
    ':disabled': {
      backgroundColor: PALETTE.secondary_dark,
      color: PALETTE.red,
    },
  },
  ctaRegister: {},
  ctaResendCode: {
    fontFamily: 'Manrope',
    color: PALETTE.secondary_dark,
    textDecoration: 'underline',
    marginBottom: COMPONENT_SPACING,
  },
};

export default theme;
