import { ThemeOptions, unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material';
import screensTheme, { ScreensTheme } from './screens';
import screenTheme, { Theme as ScreenTheme } from './screen';
import { COMPONENT_SPACING, PALETTE } from './common';

declare module '@mui/material/styles' {
  interface Theme {
    screens: ScreensTheme;
    screen: ScreenTheme;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    screens?: ScreensTheme;
    screen?: ScreenTheme;
  }
}

const defaultTheme: ThemeOptions = {
  screens: screensTheme,
  screen: screenTheme,
  typography: {
    fontFamily: 'LibreCaslonText',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '2rem',
          textTransform: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: 'Manrope',
          backgroundColor: '#F7FFE0',
          borderRadius: '20px',
          marginTop: COMPONENT_SPACING,
        },
      },
    },
  },
  palette: {
    primary: {
      main: PALETTE.primary,
    },
    secondary: {
      main: PALETTE.secondary_dark,
      light: PALETTE.secondary_light,
    },
    error: {
      main: PALETTE.tertiary,
    },
  },
};

const theme = createMuiTheme(defaultTheme);

export default theme;
