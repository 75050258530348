import { Alert } from '@mui/material';

const InfoAlert = ({ message }: { message: string }) => (
  <Alert
    severity="info"
  >
    { /* <AlertTitle>Info</AlertTitle> */ }
    {message}
  </Alert>
);

export default InfoAlert;
