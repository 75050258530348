import { type SxProps, type Theme as MuiTheme } from '@mui/material';
import { PALETTE, COMPONENT_SPACING } from '../common';

export type Theme = {
  root: SxProps<MuiTheme>;
  logoImg: SxProps<MuiTheme>;
  backgroundImage: SxProps<MuiTheme>;
  form: SxProps<MuiTheme>;
  inputEmail: SxProps<MuiTheme>;
  inputPassword: SxProps<MuiTheme>;
  ctaRoot: SxProps<MuiTheme>;
  ctaSubmit: SxProps<MuiTheme>;
  ctaRegister: SxProps<MuiTheme>;
  ctaForgotPassword: SxProps<MuiTheme>;
};

const theme: Theme = {
  root: {},
  logoImg: {
    marginBottom: '20px',
    backgroundColor: 'white',
    zIndex: 1,
    marginTop: '50%',
    width: 'fit-content',
    boxSizing: 'border-box',
    borderRadius: '10px',
    '&>svg': {
      width: '180px',
      height: '80px',
    },
  },
  backgroundImage: {
    marginTop: '20px',
    right: '-40px',
    alignSelf: 'end',
    borderRadius: '50%',
    overflow: 'hidden',
    position: 'absolute',
    width: '300px',
    height: '300px',
    '&>img': {
      width: '300px',
      height: '300px',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '1rem',
    marginTop: '2rem',
  },
  inputEmail: {
    fontFamily: 'Manrope',
    marginTop: COMPONENT_SPACING,
    backgroundColor: 'white',
    borderRadius: '80px', // Set your desired border-radius
    padding: '0px 20px 0px 20px',
  },
  inputPassword: {
    fontFamily: 'Manrope',
    marginTop: COMPONENT_SPACING,
    backgroundColor: 'white',
    borderRadius: '80px', // Set your desired border-radius
    padding: '0px 20px 0px 20px',
  },
  ctaRoot: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ctaSubmit: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    marginTop: COMPONENT_SPACING,
    backgroundColor: PALETTE.secondary_dark,
    color: PALETTE.primary,
    ':hover': {
      backgroundColor: PALETTE.secondary_dark,
    },
    ':disabled': {
      backgroundColor: PALETTE.secondary_dark,
      color: PALETTE.red,
    },
  },
  ctaRegister: {
    fontFamily: 'Manrope',
    color: PALETTE.secondary_dark,
  },
  ctaForgotPassword: {
    fontFamily: 'Manrope',
    color: PALETTE.secondary_dark,
  },
};

export default theme;
