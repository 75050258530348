import React from 'react';
import classnames from 'classnames';
import { ReactComponent as EHLogoSvg } from '../assets/img/logos/2019_HCA_logo.svg';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const EHLogo: React.FC<Props> = ({ children = <EHLogoSvg />, className }) => (
  <div className={classnames('eh-logo', className)}>
    {children}
  </div>
);

export default EHLogo;
