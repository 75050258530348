import React, { useEffect } from 'react';
import classnames from 'classnames';
import VoiceTool from '../../components/VoiceTool';
import { useDeviceTypeBySize, useQueryParams, useSession } from '../../hooks';
import { DEF_LANGUAGE } from '../../constants';
import Screen from '../../components/Screen';
import logger from '../../utils/logger';
import { useAppSelector } from '../../store/hooks';

export type VoiceToolState = 'started' | 'finished' | null;

interface Props {
  onStateChanged: (state: VoiceToolState) => void;
  partnerId: string;
  parentPartnerId: string;
}

const MediaBox: React.FC<Props> = ({
  onStateChanged,
  partnerId,
  parentPartnerId,
}) => {
  const deviceType = useDeviceTypeBySize();
  const externalUserId = useAppSelector((state) => state.auth.externalUserId);
  const { refId, lang: selectedLang = DEF_LANGUAGE } = useQueryParams();
  const [voiceToolState, setVoiceToolState] = React.useState<VoiceToolState>(null);

  // load the library and get the reference
  const session = useSession('eh-voice-tool-container');

  // notify the parent that voice state has changed
  useEffect(() => onStateChanged(voiceToolState), [voiceToolState]);

  useEffect(() => {
    if (voiceToolState) {
      return;
    }

    const handleFinish = () => {
      setVoiceToolState('finished');

      document.querySelector('.voice-tool-area')?.scrollIntoView({
        behavior: 'smooth',
      });
    };

    session.startSession({
      // url: 'http://localhost:3000',
      loadingScreenEnabled: false,
      queryParams: {
        partnerId,
        parentPartnerId,
        refId: externalUserId || refId || '',
        lang: selectedLang,
      },
      onStart: (...args) => {
        logger.log('EHWEB > Start', ...args);
        document.body.classList.add('voice-tool-started');
        setVoiceToolState('started');
      },
      onScore: (...args) => {
        logger.log('EHWEB > Score', ...args);
        handleFinish();
      },
      onEnd: (...args) => {
        logger.log('EHWEB > End', ...args);
      },
      onError: (...args) => {
        logger.log('EHWEB > Error', ...args);
        handleFinish();
      },
    });
  }, [voiceToolState, deviceType, refId, session, selectedLang]);

  return (
    <Screen gaName="media-box" className="no-margin" canNavHome canLogout>
      <div className={classnames('media-box', `media-box--${voiceToolState}`)} id="get-started">
        <div className="container">
          <div className={classnames('voice-tool-area')}>
            <div className="flipper__area">
              <VoiceTool />
            </div>
          </div>
        </div>
      </div>
    </Screen>
  );
};

export default MediaBox;
