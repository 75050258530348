import * as yup from 'yup';

export const checkValidPhoneNumber = async (phoneNumber: string, countries = ['US']) => {
  const { default: phoneNumberMetadata } = await import('libphonenumber-js/min/metadata');
  const { default: parsePhoneNumberFromString } = await import('libphonenumber-js');

  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, phoneNumberMetadata);
  return (
    parsedPhoneNumber?.isValid()
        && parsedPhoneNumber.country
        && countries.includes(parsedPhoneNumber.country)
  );
};

export const registerUserSchema = yup.object({
  firstName: yup.string().required('First name is a required field').min(2).max(16),
  lastName: yup.string().required('Last name is a required field').min(2).max(16),
  dateOfBirth: yup.date().required('Invalid Date'),
  doctor: yup.string().email().required('A Doctor must be selected'),
  email: yup.string().email().required('Email is a required field'),
  phoneNumber: yup
    .string()
    .matches(/^\+1 \(\d{3}\) \d{3}-\d{4}$/, { message: 'Invalid phone number' })
    .test('is-valid-phone', 'Invalid phone number', async (value) => await checkValidPhoneNumber(value || '') === true)
    .required('Phone Number is a required field'),
  password: yup
    .string()
    .required('Password is a required field')
    .min(8, 'Must be at least 8 characters long')
    .max(256, 'Password can\'t be more than 256 characters')
    .matches(
      /^(?=.*[a-z])/,
      ' Must Contain One Lowercase Character',
    )
    .matches(
      /^(?=.*[A-Z])/,
      '  Must Contain One Uppercase Character',
    )
    .matches(
      /^(?=.*[0-9])/,
      '  Must Contain One Number Character',
    )
    .matches(
      /^(?=.*[!@#$%^&*])/,
      '  Must Contain  One Special Case Character',
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Retype Password is a required field'),
});

export type RegisterUserType = yup.InferType<typeof registerUserSchema>;
