import axios from 'axios';
import { browserSessionPersistence, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import logger from '../utils/logger';
import { firebaseAuth } from '../utils/firebase';

export type InitLoginArgs = {
  email: string;
  password: string;
  parentPartnerId: string;
};

export const initiateCustomAuth = async ({ email, password, parentPartnerId }: InitLoginArgs) => {
  logger.log('services>auth initLogin started', { email, password, parentPartnerId });
  await setPersistence(firebaseAuth, browserSessionPersistence);
  await signInWithEmailAndPassword(firebaseAuth, email, password);

  const firebaseIdToken = await firebaseAuth.currentUser?.getIdToken(
    /* forceRefresh */ true,
  );

  const response = await axios.post('/functions/login', { firebaseIdToken, parentPartner: parentPartnerId });

  return response;
};

type Resend2FACodeArgs = {
  email: string,
  parentPartnerId: string,
};

export const resend2FACode = async ({ email, parentPartnerId }: Resend2FACodeArgs) => {
  logger.log('services>auth resend2FACode Started', { email, parentPartnerId });

  const firebaseIdToken = await firebaseAuth.currentUser?.getIdToken(
    /* forceRefresh */ true,
  );

  const response = await axios.post('/functions/resend-2fa-code', { firebaseIdToken, parentPartner: parentPartnerId });

  return response;
};

type VerifyCodeArgs = {
  code: string;
  email: string;
  parentPartnerId: string;
};

export const verifyCustomAuth = async ({ code, email, parentPartnerId }: VerifyCodeArgs) => {
  logger.log('services>auth verifyCode started', { code, email, parentPartnerId });

  const result = await axios.post('/functions/verify-2fa-code', { code, email });

  return {
    apiToken: `API-TOKEN-${code}`,
    externalUserId: result?.data?.data?.externalUserId as string || '',
    isConsentAccepted: result?.data?.data?.isConsentAccepted as boolean || false,
    userFirstName: result?.data?.data?.userFirstName as string || '',
  };
};

type RegisterUserArgs = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  doctor: string;
  email: string;
  phoneNumber: string;
  password: string;
  partner: string;
  parentPartner: string;
};

export const registerUser = async (data: RegisterUserArgs) => {
  logger.log('services>auth registerUser started', { ...data });

  const response = await axios.post('/functions/create-user', data);
  await setPersistence(firebaseAuth, browserSessionPersistence);
  await signInWithEmailAndPassword(firebaseAuth, data.email, data.password);
  return response;
};

type GetCliniciansArgs = {
  partner: string;
  parentPartner: string;
};

export const getClinicians = async (data: GetCliniciansArgs) => {
  logger.log('services>auth get clinicians started', { ...data });

  const response = await axios.post('/functions/get-clinicians', data);
  return response;
};

type ForPassSendCodeArgs = {
  email: string;
  parentPartnerId: string;
};

export const forgotPasswordSendCode = async (data: ForPassSendCodeArgs) => {
  logger.log('services>auth forgotPasswordSendCode started', { ...data });

  const response = await axios.post('/functions/forgot-password', data);
  return response;
};

type ForPassChangePasswordArgs = {
  email: string;
  code: string;
  password: string;
  parentPartnerId: string;
};

export const forgotPasswordChangePassword = async (data: ForPassChangePasswordArgs) => {
  logger.log('services>auth forgotPasswordChangePassword started', { ...data });

  const response = await axios.post('/functions/change-password', data);
  return response;
};

export const acceptConsent = async () => {
  logger.log('services>auth resend2FACode Started');

  const firebaseIdToken = await firebaseAuth.currentUser?.getIdToken(
    /* forceRefresh */ true,
  );

  const response = await axios.post('/functions/accept-consent', { firebaseIdToken });

  return response;
};
