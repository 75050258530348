import React from 'react';
import {
  Box, Button, useTheme,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MainPageLogo from '../../assets/img/main-login-img.png';
import { ReactComponent as HCALogoSvg } from '../../assets/img/logos/2019_HCA_logo.svg';
import Screen from '../../components/Screen';
import { FormatMessage } from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import * as auth from '../../store/actions/auth';
import * as nav from '../../store/actions/nav';
import NavScreen from '../../enums/navScreen';
import { loginSchema, LoginType } from '../../schemas/login';
import FormInput from '../../components/Form/Input';
import logger from '../../utils/logger';
import ErrorAlert from '../../components/ErrorAlert';
import InfoAlert from '../../components/InfoAlert';
import Loading from '../../components/Loading';

// eslint-disable-next-line @typescript-eslint/no-var-requires
// const { superstructResolver } = require('@hookform/resolvers/superstruct');

const Login = () => {
  const initialAuthState = useAppSelector((state) => state.auth.initiateAuth);

  const email = useAppSelector((state) => state.auth.email);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<LoginType>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email,
    },
  });

  const onSubmit: SubmitHandler<LoginType> = (data) => {
    dispatch(
      auth.initiateLogin({
        email: data.email,
        password: data.password,
      }),
    );
  };

  const registerClickHandler = () => {
    dispatch(nav.navTo(NavScreen.register));
  };

  const forgotPasswordClickHandler = () => {
    dispatch(nav.navTo(NavScreen.forgotPassword));
  };

  logger.debug({ errors });

  return (
    <Screen className="login" gaName="login">
      <Box sx={theme.screens.login.backgroundImage}>
        <img src={MainPageLogo} alt="HCA NC LOGO" />
      </Box>
      <Box component="form" sx={theme.screens.login.form} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={theme.screens.login.logoImg}>
          <HCALogoSvg />
        </Box>
        <FormInput
          name="Email Address"
          inputProps={{
            ...register('email'),
            sx: theme.screens.login.inputEmail,
            type: 'email',
          }}
          placeholderId="login.emailPlaceholder"
          error={errors.email?.message && 'Email must be a valid email'}
        />
        <FormInput
          name="Password"
          inputProps={{
            ...register('password'),
            sx: theme.screens.login.inputPassword,
            type: 'password',
          }}
          placeholderId="login.passwordPlaceholder"
          error={errors.password?.message && 'Invalid password'}
        />
        {/* <Box>
          <pre>
            {JSON.stringify(errors, null, 2)}
          </pre>
        </Box> */}
        {initialAuthState?.error && <ErrorAlert message={initialAuthState?.error} />}
        {initialAuthState?.info && <InfoAlert message={initialAuthState?.info} />}
        <Button
          variant="contained"
          sx={theme.screens.login.ctaSubmit}
          type="submit"
          disabled={initialAuthState.loading}
        >
          {initialAuthState.loading ? (
            <Box style={{ display: 'flex' }}>
              <Loading />
              <FormatMessage id="login.ctaSubmitLoading" />
            </Box>
          )
            : (
              <FormatMessage id="login.ctaSubmit" />
            )}
        </Button>

        <Box sx={theme.screens.login.ctaRoot}>
          <Button
            variant="text"
            sx={theme.screens.login.ctaRegister}
            type="button"
            onClick={registerClickHandler}
            disabled={initialAuthState.loading}
          >
            <FormatMessage id="login.ctaRegister" />
          </Button>
          <Button
            variant="text"
            sx={theme.screens.login.ctaForgotPassword}
            type="button"
            onClick={forgotPasswordClickHandler}
            disabled={initialAuthState.loading}
          >
            <FormatMessage id="login.ctaForgotPassword" />
          </Button>
        </Box>
      </Box>
    </Screen>
  );
};

export default Login;
