import { Reducer } from '@reduxjs/toolkit';
import { createWithLoadingStatus, WithLoadingStatus } from '../utils';
import {
  ACCEPT_CONSENT_FAIL,
  ACCEPT_CONSENT_LOADING,
  ACCEPT_CONSENT_SUCCESS,
  FOR_PASS_CHANGE_PASS_FAIL,
  FOR_PASS_CHANGE_PASS_LOADING,
  FOR_PASS_CHANGE_PASS_SUCCESS,
  FOR_PASS_SEND_CODE_FAIL,
  FOR_PASS_SEND_CODE_LOADING,
  FOR_PASS_SEND_CODE_SUCCESS,
  INITIATE_LOGIN_FAIL,
  INITIATE_LOGIN_LOADING,
  INITIATE_LOGIN_SUCCESS,
  LOG_OUT_FAIL,
  LOG_OUT_LOADING,
  LOG_OUT_SUCCESS,
  REGISTER_USER_FAIL,
  REGISTER_USER_LOADING,
  REGISTER_USER_SUCCESS,
  RESEND_CODE_FAIL,
  RESEND_CODE_LOADING,
  RESEND_CODE_SUCCESS,
  RESET_STATE,
  SET_EMAIL,
  SET_EXTERNAL_USER_ID,
  SET_LOGIN_INFO_MESSAGE,
  VERIFY_CODE_FAIL,
  VERIFY_CODE_LOADING,
  VERIFY_CODE_SUCCESS,
  GET_CLINICIANS_LOADING,
  GET_CLINICIANS_FAIL,
  GET_CLINICIANS_SUCCESS,
} from '../types/auth';

// Define a type for the slice state
export interface AuthState {
  email: string;
  externalUserId: string;
  userFirstName: string;
  clinicians: [];
  initiateAuth: WithLoadingStatus<{}>;
  verifyCode: WithLoadingStatus<{
    firebaseToken?: string;
  }>;
  resendCode: WithLoadingStatus<{}>;
  registerUser: WithLoadingStatus<{}>;
  forgotPassword: {
    sendCode: WithLoadingStatus<{
      email?: string;
      success?: true;
    }>;
    changePassword: WithLoadingStatus<{}>;
  };
  acceptConsent: WithLoadingStatus<{}>;
  logout: WithLoadingStatus<{}>;
}

// Define the initial state using that type
const initialState: AuthState = {
  email: '',
  externalUserId: '',
  userFirstName: '',
  clinicians: [],
  initiateAuth: createWithLoadingStatus({}),
  verifyCode: createWithLoadingStatus({}),
  resendCode: createWithLoadingStatus({}),
  registerUser: createWithLoadingStatus({}),
  forgotPassword: {
    sendCode: createWithLoadingStatus({}),
    changePassword: createWithLoadingStatus({}),
  },
  acceptConsent: createWithLoadingStatus({}),
  logout: createWithLoadingStatus({}),
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const authReducer: Reducer<AuthState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_EMAIL:
      return {
        ...state,
        email: payload,
      };
    case SET_EXTERNAL_USER_ID:
      return {
        ...state,
        externalUserId: payload,
      };
    case SET_LOGIN_INFO_MESSAGE:
      return {
        ...state,
        initiateAuth: {
          ...state.initiateAuth,
          info: payload,
          loading: true,
        },
      };
    case INITIATE_LOGIN_LOADING:
      return {
        ...state,
        initiateAuth: {
          ...state.initiateAuth,
          loading: true,
        },
      };
    case INITIATE_LOGIN_SUCCESS:
      return {
        ...state,
        initiateAuth: {
          ...state.initiateAuth,
          loading: false,
          error: null,
        },
      };
    case INITIATE_LOGIN_FAIL:
      return {
        ...state,
        initiateAuth: {
          ...state.initiateAuth,
          loading: false,
          error: payload,
        },
      };

    case REGISTER_USER_LOADING:
      return {
        ...state,
        registerUser: {
          ...state.registerUser,
          loading: true,
        },
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        registerUser: {
          ...state.registerUser,
          loading: false,
          error: null,
        },
      };
    case REGISTER_USER_FAIL:
      return {
        ...state,
        registerUser: {
          ...state.registerUser,
          loading: false,
          error: payload,
        },
      };
    case GET_CLINICIANS_LOADING:
      return {
        ...state,
        registerUser: {
          ...state.registerUser,
          loading: true,
        },
      };
    case GET_CLINICIANS_SUCCESS:
      return {
        ...state,
        clinicians: payload,
        registerUser: {
          ...state.registerUser,
          loading: false,
          error: null,
        },
      };
    case GET_CLINICIANS_FAIL:
      return {
        ...state,
        registerUser: {
          ...state.registerUser,
          loading: false,
          error: payload,
        },
      };
    case RESEND_CODE_LOADING:
      return {
        ...state,
        resendCode: {
          ...state.resendCode,
          loading: true,
        },
      };
    case RESEND_CODE_SUCCESS:
      return {
        ...state,
        resendCode: {
          ...state.resendCode,
          loading: false,
          error: null,
        },
      };
    case RESEND_CODE_FAIL:
      return {
        ...state,
        resendCode: {
          ...state.resendCode,
          loading: false,
          error: payload,
        },
      };
    case VERIFY_CODE_LOADING:
      return {
        ...state,
        verifyCode: {
          ...state.verifyCode,
          loading: true,
        },
      };
    case VERIFY_CODE_SUCCESS:
      return {
        ...state,
        userFirstName: payload.userFirstName,
        verifyCode: {
          ...state.verifyCode,
          loading: false,
          error: null,
          data: payload,
        },
      };
    case VERIFY_CODE_FAIL:
      return {
        ...state,
        verifyCode: {
          ...state.verifyCode,
          loading: false,
          error: payload,
        },
      };

    case FOR_PASS_SEND_CODE_LOADING:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          sendCode: {
            ...state.forgotPassword.sendCode,
            loading: true,
            data: payload || {},
          },
        },
      };
    case FOR_PASS_SEND_CODE_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          sendCode: {
            ...state.forgotPassword.sendCode,
            loading: false,
            error: null,
            data: {
              ...state.forgotPassword.sendCode.data,
              success: true,
            },
          },
          data: payload,
        },
      };
    case FOR_PASS_SEND_CODE_FAIL:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          sendCode: {
            ...state.forgotPassword.sendCode,
            loading: false,
            error: payload,
          },
        },
      };

    case FOR_PASS_CHANGE_PASS_LOADING:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          changePassword: {
            ...state.forgotPassword.changePassword,
            loading: true,
          },
        },
      };
    case FOR_PASS_CHANGE_PASS_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          changePassword: {
            ...state.forgotPassword.changePassword,
            loading: false,
            error: null,
          },
          data: payload,
        },
      };
    case FOR_PASS_CHANGE_PASS_FAIL:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          changePassword: {
            ...state.forgotPassword.changePassword,
            loading: false,
            error: payload,
          },
        },
      };
    case ACCEPT_CONSENT_LOADING:
      return {
        ...state,
        acceptConsent: {
          ...state.acceptConsent,
          loading: true,
        },
      };
    case ACCEPT_CONSENT_SUCCESS:
      return {
        ...state,
        acceptConsent: {
          ...state.acceptConsent,
          loading: false,
          error: null,
        },
      };
    case ACCEPT_CONSENT_FAIL:
      return {
        ...state,
        acceptConsent: {
          ...state.acceptConsent,
          loading: false,
          error: payload,
        },
      };
    case LOG_OUT_LOADING:
      return {
        ...state,
        logout: {
          ...state.logout,
          loading: true,
        },
      };
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        logout: {
          ...state.logout,
          loading: false,
          error: null,
        },
      };
    case LOG_OUT_FAIL:
      return {
        ...state,
        initiateAuth: {
          ...state.logout,
          loading: false,
          error: payload,
        },
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
