import {
  FormLabel,
  styled as muiStyled,
} from '@mui/material';

export default muiStyled(FormLabel)(() => ({
  '&': {
    // position: 'absolute',
    fontFamily: 'Manrope',
    fontSize: '14px',
    left: 0,
    bottom: '-0.85rem',
    margin: 0,
    color: 'white',
  },
}));
