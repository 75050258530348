import React from 'react';
import { useAppSelector } from '../../store/hooks';
import NavScreen from '../../enums/navScreen';
import Login from '../Login';
import VerifyCode from '../VerifyCode';
import Register from '../RegisterUser';
import ForgotPassword from '../ForgotPassword';
import MainMenu from '../MainMenu';
import Consent from '../Consent';

const NavScreens = () => {
  const currentScreen = useAppSelector((state) => state.nav.current);

  if (currentScreen === NavScreen.login) {
    return <Login />;
  }

  if (currentScreen === NavScreen.register) {
    return <Register />;
  }

  if (currentScreen === NavScreen.forgotPassword) {
    return <ForgotPassword />;
  }

  if (currentScreen === NavScreen.verifyCode) {
    return <VerifyCode />;
  }

  if (currentScreen === NavScreen.mainMenu) {
    return <MainMenu />;
  }

  if (currentScreen === NavScreen.consent) {
    return <Consent />;
  }

  return null;
};

export default NavScreens;
