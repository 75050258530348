import React from 'react';
import GlassesOutlinedIcon from '../../assets/img/glasses.svg';
import StethoscopeOutlinedIcon from '../../assets/img/stethoscope.svg';
// import CommentOutlinedIcon from '../../assets/img/comment.svg';
import { FormatMessage } from '../../i18n';
import { RECORDING_DURATION_IN_SEC, RECORDING_DURATION_IN_MIN } from '../../constants';
// import ImgRecordUrl from '../../assets/img/record-1.png';
// import ImgTimerUrl from '../../assets/img/timer-1.png';
// import ImgTopicUrl from '../../assets/img/topic-1.png';

const INSTRUCTIONS_LIST = [
  {
    id: 'resultsToDoctor',
    imgUrl: null, // ImgTopicUrl,
    Icon: GlassesOutlinedIcon,
    alt: 'Results Sent to Your Doctor',
    title: <FormatMessage id="home.instructions.first.title" />,
    desc: <FormatMessage id="home.instructions.first.desc" />,
  },
  {
    id: 'insigths',
    imgUrl: null, // ImgTimerUrl,
    Icon: StethoscopeOutlinedIcon,
    alt: 'Get Your Unique Mental Health Insights',
    title: (
      <FormatMessage
        id="home.instructions.second.title"
        values={{
          recordingDurationInSec: RECORDING_DURATION_IN_SEC,
          recordingDurationInMin: RECORDING_DURATION_IN_MIN,
        }}
      />
    ),
    desc: <FormatMessage id="home.instructions.second.desc" />,
  },
];

type Props = {
  onGetStarted: () => void;
};

const Instructions: React.FC<Props> = ({ onGetStarted }) => (
  <div className="instructions">
    <div className="container">
      <h2 className="title">
        <FormatMessage id="home.instructions.title" />
      </h2>
      <div className="instruction__list">
        {
          INSTRUCTIONS_LIST.map((item) => (
            <div className="instruction__item" key={item.id}>
              <div className="instruction__icon">
                {
                  item.imgUrl
                    ? <img src={item.imgUrl} alt={item.alt} />
                    : <img alt={item.alt} src={item.Icon} />
                }
              </div>
              <h3 className="instruction__title">{item.title}</h3>
              <p className="instruction__desc">{item.desc}</p>
            </div>
          ))
        }
      </div>
      <div className="section-get-started hidden mobile-visible">
        <button className="button button--solid" onClick={onGetStarted} type="button">
          <FormatMessage id="home.header.button.title" />
        </button>
      </div>
    </div>
  </div>
);

export default Instructions;
