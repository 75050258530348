import * as yup from 'yup';
import { email } from './base';

export const sendCodeSchema = yup.object({
  email: email(),
});

export type SendCodeType = yup.InferType<typeof sendCodeSchema>;

export const changePasswordSchema = yup.object({
  code: yup.string().required().min(6).max(6),
  password: yup
    .string()
    .required()
    .min(8, 'Must be at least 8 characters long')
    .max(256, 'Password can\'t be more than 256 characters')
    .matches(
      /^(?=.*[a-z])/,
      ' Must Contain One Lowercase Character',
    )
    .matches(
      /^(?=.*[A-Z])/,
      '  Must Contain One Uppercase Character',
    )
    .matches(
      /^(?=.*[0-9])/,
      '  Must Contain One Number Character',
    )
    .matches(
      /^(?=.*[!@#$%^&*])/,
      '  Must Contain  One Special Case Character',
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required(),
});

export type ChangePasswordType = yup.InferType<typeof changePasswordSchema>;
