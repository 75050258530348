const useQueryParam = () => {
  const url = new URL(window.location.href);

  const entries = url.searchParams.entries();
  const result: Record<string, string> = {};

  Array.from(entries).forEach(([k, v]) => {
    result[k] = v;
  });

  return result;
};

export default useQueryParam;
