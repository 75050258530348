import React, { useMemo, useState } from 'react';
import MuiInput, { InputProps } from '@mui/material/Input';
import { FormControl, SxProps } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { useIntl } from 'react-intl';
import HelperText from '../HelperText';
import Label from '../FormLabel';
import Tooltip from '../Tooltip';

export type Props = {
  name: String;
  inputProps: InputProps;
  input?: React.ReactNode;
  sx?: SxProps,
  error?: React.ReactNode;
  // i18n id for the placeholder and tooltip
  placeholderId?: string;
  tooltipId?: string;
};

const FormInput: React.FC<Props> = ({
  name,
  inputProps,
  input,
  sx,
  error,
  placeholderId,
  tooltipId,
}) => {
  const [isTyping, setIsTyping] = useState(false);

  const intl = useIntl();
  const placeholderText = useMemo(
    () => placeholderId && intl.formatMessage({ id: placeholderId }),
    [placeholderId],
  );
  const tooltipText = useMemo(
    () => tooltipId && intl.formatMessage({ id: tooltipId }),
    [tooltipId],
  );

  const handleFocus = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    setIsTyping(false);
  };

  return (
    <FormControl fullWidth sx={sx}>
      <Label focused={false}>
        {name}
        {tooltipId && (
          <Tooltip
            // eslint-disable-next-line react/no-danger
            title={<div dangerouslySetInnerHTML={{ __html: tooltipText ?? '' }} />}
            arrow
            placement="right-start"
            enterTouchDelay={0}
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  fontFamily: 'Manrope',
                  padding: '5px',
                  margin: '0px 2px 2px 2px',
                  backgroundColor: 'rgba(247, 255, 224, 0.9)',
                  color: '#000000',
                  '& p': {
                    fontSize: '12px',
                  },
                  '& li': {
                    fontSize: '12px',
                  },
                },
                '& .MuiTooltip-arrow': {
                  color: 'rgba(247, 255, 224, 0.9)',
                },
              },
            }}
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </Label>
      {input || (
      <MuiInput
        // eslint-disable-next-line
        {...inputProps}
        error={!!error}
        placeholder={placeholderText}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disableUnderline
      />
      )}
      {error && !isTyping && (
        <HelperText sx={{}}>
          {error}
        </HelperText>
      )}
    </FormControl>
  );
};

export default FormInput;
