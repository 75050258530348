import { Alert } from '@mui/material';

const ErrorAlert = ({ message }: { message: string }) => (
  <Alert
    severity="error"
  >
    { /* <AlertTitle>Error</AlertTitle> */ }
    {message}
  </Alert>
);

export default ErrorAlert;
