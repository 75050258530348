import { Language } from './components/LangList';

export const ENV_TO_FB_PROJECT_ID = {
  dev: 'rising-higher-dev',
  qa: 'rising-higher-qa',
  demo: 'rising-higher-demo',
  prod: 'ellipsis-app-kittreskin',
  prod2: 'eh-prod-2',
};

export const ENV_TO_CDN_URL = {
  dev: 'https://cdn-dev.ellipsishealth.net',
  qa: 'https://cdn-qa.ellipsishealth.net',
  demo: 'https://cdn-demo.ellipsishealth.net',
  prod: 'https://cdn.ellipsishealth.net',
  prod2: 'https://cdn-prod2.ellipsishealth.net',
};

export const FIREBASE_PROJECT_ID = (
  ENV_TO_FB_PROJECT_ID[process.env.REACT_APP_ENV] || ENV_TO_FB_PROJECT_ID.dev
);

export const CDN_URL = ENV_TO_CDN_URL[process.env.REACT_APP_ENV] || ENV_TO_CDN_URL.dev;

export const ENV_TO_SITE_URL = {
  // dev: 'http://localhost:3000',
  dev: 'https://voice-collection-widget-dev.web.app',
  qa: 'https://voice-collection-widget-qa.web.app',
  demo: 'https://eh-optum-widget-demo.ellipsishealth.net',
  prod: 'https://eh-optum-widget.ellipsishealth.net',
  prod2: 'https://eh-optum-widget.ellipsishealth.net', // Change this once deployed
};

export const SITE_URL = ENV_TO_SITE_URL[process.env.REACT_APP_ENV] || ENV_TO_SITE_URL.dev;

export const AppConfig = {
  recordingDurationInSec: 60,
  languages: ['en'] as Array<Language>,
  size: {
    width: 375,
    height: 670,
  },
  idleTimeout: 20 * 60 * 1000,
  /* We'll continue to pass a partnerId to maintain some backwards compatability */
  partnerId: 'HCA_NC_VOICE_TOOL',
  parentPartnerId: 'HCA',
  voiceToolPartnerId: 'HCA_NC_VOICE_TOOL',
  wellnessPartnerId: 'HCA_NC_WELLNESS',
};

export const PARTNER_ID = process.env.PARTNER_ID || AppConfig.partnerId;
export const PARENT_PARTNER_ID = process.env.PARENT_PARTNER_ID || AppConfig.parentPartnerId;
export const VOICE_TOOL_PARTNER_ID = AppConfig.voiceToolPartnerId;
export const WELLNESS_PARTNER_ID = AppConfig.wellnessPartnerId;

export const RECORDING_DURATION_IN_SEC = AppConfig.recordingDurationInSec;
export const RECORDING_DURATION_IN_MIN = Math.ceil(AppConfig.recordingDurationInSec / 60);

export const DEF_LANGUAGE = AppConfig.languages[0];

// DATADOG
export const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID;
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
