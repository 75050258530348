/**
 * A logger module where we can enable, disable or redirect logs
 */

const log = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'dev') {
    // eslint-disable-next-line
    console.log(...args);
  }
};

const debug = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'dev') {
    // eslint-disable-next-line
    console.debug(...args);
  }
};

const logger = {
  log,
  debug,
} as const;

export default logger;
