const ja = {
  home: {
    header: {
      title: '{recordingDurationInMin}分間幸福度チェック',
      poweredby: {
        title: '搭載',
      },
      button: {
        title: 'スタート',
      },
    },
    instructions: {
      title: 'やり方',
      findQuietPlace: {
        title: '静かな場所で行う',
      },
      record60Secs: {
        title: '心の声を感じとり{recordingDurationInSec}秒録音する',
      },
      receiveInsights: {
        title: '結果を見る',
      },
    },

    results: {
      failureNote: 'スコアを表示することができません。',
      successNote: '*これらのスコアはデモンストレーションのみを目的としています。',
      labels: {
        stress: 'ストレススコア',
        anxiety: '不安スコア',
        depression: 'うつスコア',
      },
    },
  },
};

export default ja;
