import {
  NAV_TO,
} from '../types/nav';
import { AppThunk } from '..';
import NavScreen from '../../enums/navScreen';

// eslint-disable-next-line import/prefer-default-export
export const navTo = (
  (screen: NavScreen): AppThunk => (
    async (dispatch) => {
      dispatch({
        type: NAV_TO,
        payload: screen,
      });
    }
  )
);
