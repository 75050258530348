import Loading_GIF from '../../assets/img/loading.gif';

const Loading = () => (
  <img
    src={Loading_GIF}
    alt="loading...:"
    style={{
      objectFit: 'contain',
      height: '1.8em',
      width: '1.8em',
      paddingRight: '5px',
    }}
  />
);

export default Loading;
