import {
  Tooltip,
  styled as muiStyled,
} from '@mui/material';

export default muiStyled(Tooltip)(() => ({
  '&': {
    fontFamily: 'Manrope',
    padding: 0,
    margin: '0px 2px 2px 2px',
    color: 'rgba(247, 255, 224, 0.9)',
  },
}));
