import { CDN_URL } from '../../constants';

const en = {
  home: {
    header: {
      title: 'Track your mental health',
      subTitle: 'A key step in your maternal journey',
      headline: 'Help your doctor better tailor your care with this 60-second wellness tool.',
      poweredby: {
        title: 'powered by',
      },
      button: {
        title: 'Get Started',
      },
    },
    instructions: {
      title: 'Motherhood can be stressful. Getting support shouldn’t be.',
      first: {
        title: 'Results Sent to Your Doctor',
        desc: 'Allows your physician to understand how you really feel.',
      },
      second: {
        title: 'Get Your Unique Mental Health Insights',
        desc: 'Complete a 60-second screening or answer a quick set of questions.',
      },
      third: {
        title: 'Wellness Coaching',
        desc: 'Message with your own wellness coach for optional (and free!) one-on-one support.',
      },
    },
    reviews: {
      title: '92% recommend this to other to-be mamas.',
      first: {
        title: '',
        desc: '“This has helped me navigate a difficult time at no cost.”',
      },
      second: {
        title: '',
        desc: '“I liked the fact that I didn’t get a canned response like I do with my school counselors. Instead, I got a personalized message that I could actually use.”',
      },
      third: {
        title: '',
        desc: '“I appreciated the tips section which gave me some actionable steps I can take.”',
      },
    },
    results: {
      failureNote: 'Failed to score the session',
      successNote: '*These scores are for demo purposes only',
      labels: {
        stress: 'Stress Score',
        anxiety: 'Anxiety Score',
        depression: 'Depression Score',
      },
    },
    footer: {
      title: 'We’re here for you, mama.',
    },
  },
  nav: {
    back: 'Back',
  },
  login: {
    ctaSubmit: 'Login',
    ctaSubmitLoading: 'Logging In',
    ctaRegister: 'New user? Click here.',
    ctaForgotPassword: 'Forgot password',
    emailPlaceholder: 'Email address',
    passwordPlaceholder: 'Password',
  },
  registerUser: {
    title: 'Create an account',
    ctaSubmit: 'Register',
    ctaSubmitLoading: 'Registering',
    firstNamePlaceholder: 'First name',
    lastNamePlaceholder: 'Last name',
    dateOfBirthPlaceholder: 'Date of birth (MM/DD/YYYY)',
    emailPlaceholder: 'Email address',
    phoneNumberPlaceholder: 'Phone (###-###-####)',
    passwordPlaceholder: 'Password',
    passwordTooltip: '<p>Password Requirements</p><ul><li>Minimum of 8 characters</li><li>1 uppercase letter</li><li>1 lowercase letter</li><li>1 number</li><li>1 special character</li></ul>',
    confirmPasswordPlaceholder: 'Retype password',
    doctorPlaceholder: 'OB/GYN Doctor',
  },
  verifyCode: {
    title: 'Confirmation',
    description: 'To verify your account, enter the code sent to your email:',
    ctaSubmit: 'Verify Code',
    ctaVerifingCode: 'Verifying Code',
    contactSupport: 'Not getting the code? Please check your Spam folder or contact <email>support@missionmamas.com</email> for help.',
    otpPlaceholder: 'OTP',
    ctaResendCode: 'Resend Code',
  },
  forgotPassword: {
    title: 'Forgot password',
    description: 'To change your password, enter your email:',
    ctaSendCode: 'Send Code to Phone',
    ctaSendingCode: 'Sending Code to Phone',
    ctaResendCode: 'Resend Code to Phone',
    ctaSubmit: 'Change Password',
    ctaChangingPassword: 'Changing Password',
    emailPlaceholder: 'Email address',
    codePlaceholder: 'Enter code sent to phone',
    passwordPlaceholder: 'Enter password',
    passwordTooltip: '<p>Password Requirements</p><ul><li>Minimum of 8 characters</li><li>1 uppercase letter</li><li>1 lowercase letter</li><li>1 number</li><li>1 special character</li></ul>',
    confirmPasswordPlaceholder: 'Retype password',
    contactSupport: 'Not getting the code? Contact <email>support@missionmamas.com</email> for help.',
  },
  screen: {
    navBack: 'Back',
    navHome: 'Home',
    navLogout: 'Logout',
  },
  mainMenu: {
    title: 'Hi {userFirstName},',
    description: 'complete a mental health check-in to help your doctor personalize your care.',
    moodWordWidgetLink: 'Mental Health Check-In',
    wellnessWidgetLink: 'Message My Wellness Coach',
  },
  consent: {
    title: 'Consent',
    description: 'Before we begin, please read through and agree to the following:',
    agreement: 'I consent',
    ctaSubmit: 'Submit',
    ctaSubmitLoading: 'Submitting',
    language: `This app utilizes automated analysis of your voice and speech content to make certain behavioral health assessments and to inform and improve your care. This app is not intended to replace a licensed care provider, and the assessments made should not be relied upon to create or execute a treatment plan without the context and expertise of such a provider. If you are experiencing a medical or behavioral health emergency, please dial 911 or otherwise seek the care of a licensed medical professional. By continuing, you agree:{br}• We may record, store and process the information you give us through the app and via other means. We keep all sensitive information secure and store it only in conformity with best practices.{br}• You agree to be bound by the Ellipsis Health, Inc. <link>"text": "Privacy Policy", "url": "https://www.ellipsishealth.com/privacy"</link> and <link>"text": "Terms of Service", "url": "https://www.ellipsishealth.com/terms"</link>.{br}• We may contact you via the means of contact you provide us, and we may share the information you’ve given us via the app and our resultant analysis with your care provider.{br}• You are 18 years of age or an emancipated minor, and you have the capacity to understand and agree with this consent and the functions of the app. If you are unable to consent to the use of Voxey you must have the consent of your parent or guardian before continuing. For more information about Ellipsis Health, please see <link>"text": "this FAQ", "url": "${CDN_URL}/webapp/hca/pdfs/faq-20231215.pdf"</link>.`,
  },
};

export default en;
