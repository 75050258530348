import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

interface Props extends MessageDescriptor {
  values?: Record<string, React.ReactNode>;
}

const I18nEmail = (chunks: string) => (
  <a href={`mailto:${chunks}`}>
    {chunks}
  </a>
);

const I18nLink = (linkObject: string[]) => {
  try {
    const jsonString = linkObject[0].replace(/\\"/g, '"');
    const obj = JSON.parse(`{${jsonString}}`);

    return (
      <a href={obj.url} target="_blank" rel="noopener noreferrer" style={{ display: 'contents' }}>
        {obj.text}
      </a>
    );
  } catch (error) {
    return null;
  }
};

const FormatMessage = ({ values = {}, ...props }: Props) => (
  <FormattedMessage
    // eslint-disable-next-line
    {...props}
    values={{
      ...values,
      br: <br />,
      email: I18nEmail,
      link: I18nLink,
    }}
  />
);

export default FormatMessage;
