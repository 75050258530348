import { signOut } from 'firebase/auth';
import {
  INITIATE_LOGIN_LOADING,
  INITIATE_LOGIN_SUCCESS,
  INITIATE_LOGIN_FAIL,
  VERIFY_CODE_FAIL,
  VERIFY_CODE_LOADING,
  VERIFY_CODE_SUCCESS,
  REGISTER_USER_LOADING,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  FOR_PASS_SEND_CODE_LOADING,
  FOR_PASS_SEND_CODE_SUCCESS,
  FOR_PASS_SEND_CODE_FAIL,
  FOR_PASS_CHANGE_PASS_LOADING,
  FOR_PASS_CHANGE_PASS_SUCCESS,
  FOR_PASS_CHANGE_PASS_FAIL,
  SET_EMAIL,
  LOG_OUT_LOADING,
  RESET_STATE,
  LOG_OUT_SUCCESS,
  SET_LOGIN_INFO_MESSAGE,
  SET_EXTERNAL_USER_ID,
  RESEND_CODE_LOADING,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_FAIL,
  ACCEPT_CONSENT_LOADING,
  ACCEPT_CONSENT_FAIL,
  ACCEPT_CONSENT_SUCCESS,
  GET_CLINICIANS_LOADING,
  GET_CLINICIANS_FAIL,
  GET_CLINICIANS_SUCCESS,
} from '../types/auth';
import * as AuthService from '../../services/auth';
import { PARTNER_ID, PARENT_PARTNER_ID } from '../../constants';
import { AppThunk } from '..';
import { NAV_TO } from '../types/nav';
import NavScreen from '../../enums/navScreen';
import logger from '../../utils/logger';
import { firebaseAuth } from '../../utils/firebase';

type InitLoginArgs = {
  email: string;
  password: string;
};

export const initiateLogin = (
  ({ email, password }: InitLoginArgs): AppThunk => (
    async (dispatch) => {
      try {
        // start the loading state
        dispatch({
          type: INITIATE_LOGIN_LOADING,
        });

        // save the email
        dispatch({
          type: SET_EMAIL,
          payload: email,
        });

        // call the service
        await AuthService.initiateCustomAuth({
          email,
          password,
          parentPartnerId: PARENT_PARTNER_ID,
        });

        // update the success state
        dispatch({
          type: INITIATE_LOGIN_SUCCESS,
        });

        // navigate to the code verification screen
        dispatch({
          type: NAV_TO,
          payload: NavScreen.verifyCode,
        });
      } catch (error: any) {
        if (error?.response?.data?.message) {
          dispatch({
            type: INITIATE_LOGIN_FAIL,
            payload: error?.response?.data?.message,
          });
        } else {
          dispatch({
            type: INITIATE_LOGIN_FAIL,
            payload: 'Invalid email and password',
          });
        }
      }
    }
  )
);

type VerifyCodeArgs = {
  code: string;
  email: string;
};

export const verifyCode = (
  ({ email, code }: VerifyCodeArgs): AppThunk => (
    async (dispatch) => {
      try {
        // start the loading state
        dispatch({
          type: VERIFY_CODE_LOADING,
        });

        // call the service
        const result = await AuthService.verifyCustomAuth({
          email,
          code,
          parentPartnerId: PARENT_PARTNER_ID,
        });

        // save the success state
        dispatch({
          type: VERIFY_CODE_SUCCESS,
          payload: result,
        });

        // Save the external user id
        dispatch({
          type: SET_EXTERNAL_USER_ID,
          payload: result.externalUserId,
        });

        // Check if user has accepted the consent
        if (result.isConsentAccepted) {
          // navigate to the main menu
          dispatch({
            type: NAV_TO,
            payload: NavScreen.mainMenu,
          });
        } else {
          dispatch({
            type: NAV_TO,
            payload: NavScreen.consent,
          });
        }
      } catch (error: any) {
        if (error?.response?.data?.message) {
          dispatch({
            type: VERIFY_CODE_FAIL,
            payload: error?.response?.data?.message,
          });
        } else {
          dispatch({
            type: VERIFY_CODE_FAIL,
            payload: 'Error Verifying Code',
          });
        }
      }
    }
  )
);

type Resend2FACodeArgs = {
  email: string;
};

export const resend2FACode = (
  ({ email }: Resend2FACodeArgs): AppThunk => (
    async (dispatch) => {
      logger.log('Resending 2FA Code');
      try {
        // start the loading state
        dispatch({
          type: RESEND_CODE_LOADING,
        });

        await AuthService.resend2FACode({
          email,
          parentPartnerId: PARENT_PARTNER_ID,
        });

        // update the success state
        dispatch({
          type: RESEND_CODE_SUCCESS,
        });
      } catch (error) {
        dispatch({
          type: RESEND_CODE_FAIL,
        });
      }
    }
  )
);

type RegisterUserArgs = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  doctor: string;
  email: string;
  phoneNumber: string;
  password: string;
};

export const registerUser = (
  (data: RegisterUserArgs): AppThunk => (
    async (dispatch) => {
      try {
        // start the loading state
        dispatch({
          type: REGISTER_USER_LOADING,
        });

        // call the service
        logger.log('Calling auth service');
        const result = await AuthService.registerUser({
          ...data,
          partner: PARTNER_ID,
          parentPartner: PARENT_PARTNER_ID,
        });

        if (result.status === 200 && result?.data?.message === 'Use your existing Ellipsis Health credentials to login.') {
          /* Handle edge case where User already has an existing ellipsis health login */
          logger.log('User already exists within ellipsis health');
          dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: null,
          });

          dispatch({
            type: SET_LOGIN_INFO_MESSAGE,
            payload: result.data.message,
          });

          // navigate to the login screen
          dispatch({
            type: NAV_TO,
            payload: NavScreen.login,
          });
        } else {
          /* Standard case where this is a brand new EH user. */
          // save the uer email
          dispatch({
            type: SET_EMAIL,
            payload: data.email,
          });

          // save the success state
          dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: null,
          });

          // navigate to the code verification screen
          dispatch({
            type: NAV_TO,
            payload: NavScreen.verifyCode,
          });
        }
      } catch (error: any) {
        logger.log('Register user failed');
        if (error?.response?.data?.message) {
          dispatch({
            type: REGISTER_USER_FAIL,
            payload: error?.response?.data?.message,
          });
        } else {
          dispatch({
            type: REGISTER_USER_FAIL,
            payload: 'Error Registering User',
          });
        }
      }
    }
  )
);

export const getClinicians = (
  (): AppThunk => (
    async (dispatch) => {
      try {
        // start the loading state
        dispatch({
          type: GET_CLINICIANS_LOADING,
        });

        // call the service
        logger.log('Calling get clinicians');
        const result = await AuthService.getClinicians({
          partner: PARTNER_ID,
          parentPartner: PARENT_PARTNER_ID,
        });

        if (result.status !== 200 || !result.data.clinicians) {
          dispatch({
            type: GET_CLINICIANS_FAIL,
            payload: 'Error getting clinicians',
          });
        }

        // save the list of clinicians
        dispatch({
          type: GET_CLINICIANS_SUCCESS,
          payload: result.data.clinicians,
        });
      } catch (error: any) {
        logger.log('Get Clinicians function failed');
        if (error?.response?.data?.message) {
          dispatch({
            type: GET_CLINICIANS_FAIL,
            payload: error?.response?.data?.message,
          });
        } else {
          dispatch({
            type: GET_CLINICIANS_FAIL,
            payload: 'Error Returning clincians list',
          });
        }
      }
    }
  )
);

type ForPassSendCodeArgs = {
  email: string;
};

export const forgotPasswordSendCode = (
  (data: ForPassSendCodeArgs): AppThunk => (
    async (dispatch) => {
      try {
        // start the loading state
        dispatch({
          type: FOR_PASS_SEND_CODE_LOADING,
          payload: data,
        });

        // call the service
        const result = await AuthService.forgotPasswordSendCode({
          ...data,
          parentPartnerId: PARENT_PARTNER_ID,
        });

        // save the success state
        dispatch({
          type: FOR_PASS_SEND_CODE_SUCCESS,
          payload: result,
        });
      } catch (error: any) {
        if (error?.response?.data?.message) {
          dispatch({
            type: FOR_PASS_SEND_CODE_FAIL,
            payload: error?.response?.data?.message,
          });
        } else {
          dispatch({
            type: FOR_PASS_SEND_CODE_FAIL,
            payload: 'Error Sending Forgot Password Code',
          });
        }
      }
    }
  )
);

type ForPassChangePasswordArgs = {
  email: string;
  code: string;
  password: string;
};

export const forgotPasswordChangePassword = (
  (data: ForPassChangePasswordArgs): AppThunk => (
    async (dispatch) => {
      try {
        // start the loading state
        dispatch({
          type: FOR_PASS_CHANGE_PASS_LOADING,
        });

        // call the service
        const result = await AuthService.forgotPasswordChangePassword({
          ...data,
          parentPartnerId: PARENT_PARTNER_ID,
        });

        // save the success state
        dispatch({
          type: FOR_PASS_CHANGE_PASS_SUCCESS,
          payload: result,
        });

        // navigate to the login screen
        dispatch({
          type: NAV_TO,
          payload: NavScreen.login,
        });
      } catch (error: any) {
        if (error?.response?.data?.message) {
          dispatch({
            type: FOR_PASS_CHANGE_PASS_FAIL,
            payload: error?.response?.data?.message,
          });
        } else {
          dispatch({
            type: FOR_PASS_CHANGE_PASS_FAIL,
            payload: 'Error Changing Password',
          });
        }
      }
    }
  )
);

export const acceptConsent = (
  (): AppThunk => (
    async (dispatch) => {
      try {
        // start the loading state
        dispatch({
          type: ACCEPT_CONSENT_LOADING,
        });

        await AuthService.acceptConsent();

        // update the success state
        dispatch({
          type: ACCEPT_CONSENT_SUCCESS,
        });

        // Navigate to home.
        dispatch({
          type: NAV_TO,
          payload: NavScreen.mainMenu,
        });
      } catch (error) {
        dispatch({
          type: ACCEPT_CONSENT_FAIL,
        });
      }
    }
  )
);

export const logout = (
  (): AppThunk => (
    async (dispatch) => {
      try {
        // start the loading state
        dispatch({
          type: LOG_OUT_LOADING,
        });

        signOut(firebaseAuth);

        // reset the state
        dispatch({
          type: RESET_STATE,
        });

        // update the success state
        dispatch({
          type: LOG_OUT_SUCCESS,
        });

        // navigate to the login screen
        dispatch({
          type: NAV_TO,
          payload: NavScreen.login,
        });
      } catch (error) {
        dispatch({
          type: INITIATE_LOGIN_FAIL,
        });
      }
    }
  )
);
