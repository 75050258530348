enum NavScreen {
  login,
  verifyCode,
  register,
  forgotPassword,
  voiceWidget,
  wellnessWidget,
  mainMenu,
  consent,
}

export default NavScreen;
