import type { SxProps, Theme as MuiTheme } from '@mui/material';
import { PALETTE } from '../common';

export type Theme = {
  root: SxProps<MuiTheme>;
  title: SxProps<MuiTheme>;
  description: SxProps<MuiTheme>;
  language: SxProps<MuiTheme>;
  agreement: SxProps<MuiTheme>;
  ctaSubmit: SxProps<MuiTheme>;
};

const theme: Theme = {
  root: {},
  title: {
    padding: '0px 16px 0px 16px',
    fontSize: '26px',
    fontWeight: '700',
    lineHeight: '40px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: 'white',
  },
  description: {
    fontFamily: 'Manrope',
    padding: '0px 16px 20px 16px',
    fontSize: '14px',
    color: 'white',
  },
  language: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Manrope',
    margin: '0px 16px 20px 16px',
    background: 'white',
    overflow: 'auto',
    padding: '1rem',
  },
  agreement: {
    fontFamily: 'Manrope',
    color: 'white',
  },
  ctaSubmit: {
    fontFamily: 'Manrope',
    fontWeight: 700,
    margin: '16px',
    backgroundColor: PALETTE.secondary_dark,
    color: PALETTE.primary,
    ':hover': {
      backgroundColor: PALETTE.secondary_dark,
    },
  },
};

export default theme;
