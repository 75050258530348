import React, { useEffect } from 'react';
import { Box, Button, useTheme } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import classNames from 'classnames';
import logger from '../../utils/logger';
import { FormatMessage } from '../../i18n';
import * as navActions from '../../store/actions/nav';
import * as authActions from '../../store/actions/auth';
import NavScreen from '../../enums/navScreen';
import { useAppDispatch } from '../../store/hooks';
import { AppConfig } from '../../constants';
import './index.scss';

type Props = {
  gaName: string;
  canNavBack?: boolean;
  className?: string;
  canLogout?: boolean;
  canNavHome?: boolean;
};

const Screen: React.FC<Props> = ({
  children, gaName, canNavBack, className, canLogout, canNavHome,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  useEffect(() => {
    logger.log(`Showing ${gaName}`);
  }, []);

  const onNavBack = () => {
    dispatch(navActions.navTo(NavScreen.login));
  };

  const onNavHome = () => {
    dispatch(navActions.navTo(NavScreen.mainMenu));
  };

  const onNavLogout = () => {
    dispatch(authActions.logout());
  };

  return (
    <Box
      className={classNames('screen-card', className)}
      sx={{
        ...theme.screen.root,
        height: AppConfig.size.height,
        width: AppConfig.size.width,
      }}
    >
      <Box sx={theme.screen.nav.root}>
        {canNavBack && (
          <Button variant="text" sx={theme.screen.nav.ctaBack.root} onClick={onNavBack}>
            <ArrowBackIosNewOutlinedIcon sx={theme.screen.nav.ctaBack.icon} />
            <Box sx={theme.screen.nav.ctaBack.text}>
              <FormatMessage id="screen.navBack" />
            </Box>
          </Button>
        )}
        {canNavHome && (
          <Button variant="text" sx={theme.screen.nav.ctaHome.root} onClick={onNavHome}>
            <Box sx={theme.screen.nav.ctaHome.text}>
              <FormatMessage id="screen.navHome" />
            </Box>
          </Button>
        )}
        {canLogout && (
          <Button variant="text" sx={theme.screen.nav.ctaLogout.root} onClick={onNavLogout}>
            <Box sx={theme.screen.nav.ctaLogout.text}>
              <FormatMessage id="screen.navLogout" />
            </Box>
          </Button>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default Screen;
