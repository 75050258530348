import {
  Box, Button, useTheme, Checkbox,
} from '@mui/material';
import { useState } from 'react';
import logger from '../../utils/logger';
import Screen from '../../components/Screen';
import { FormatMessage } from '../../i18n';
import * as auth from '../../store/actions/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Loading from '../../components/Loading';
import { PALETTE } from '../../theme/common';

const Consent = () => {
  logger.log('Showing consent screen');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const acceptConsentState = useAppSelector((state) => state.auth.acceptConsent);

  const handleSubmit = () => {
    dispatch(auth.acceptConsent());
  };

  return (
    <Screen className="consent" gaName="consent" canLogout>
      <Box sx={theme.screens.consent.title}>
        <FormatMessage id="consent.title" />
      </Box>
      <Box sx={theme.screens.consent.description}>
        <FormatMessage id="consent.description" />
      </Box>
      <Box
        maxHeight={['50%', '60%']}
        sx={theme.screens.consent.language}
      >
        <FormatMessage id="consent.language" />
      </Box>
      <Box sx={theme.screens.consent.agreement}>
        <Checkbox
          style={{ color: '#FFCCD5' }}
          checked={isAccepted}
          onChange={(e) => setIsAccepted(e.target.checked)}
        />
        <FormatMessage id="consent.agreement" />
      </Box>
      <Button
        variant="contained"
        sx={theme.screens.consent.ctaSubmit}
        type="submit"
        disabled={!isAccepted || acceptConsentState.loading}
        onClick={handleSubmit}
      >
        {acceptConsentState.loading ? (
          <Box style={{ display: 'flex', color: PALETTE.red }}>
            <Loading />
            <FormatMessage id="consent.ctaSubmitLoading" />
          </Box>
        )
          : (
            <FormatMessage id="consent.ctaSubmit" />
          )}
      </Button>
    </Screen>
  );
};

export default Consent;
