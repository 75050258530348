import React from 'react';
import QuoteIcon from '../../assets/img/quote.svg';
import { FormatMessage } from '../../i18n';

const REVIEW_LIST = [
  {
    id: 'resultsToDoctor',
    imgUrl: null, // ImgTopicUrl,
    Icon: QuoteIcon,
    alt: 'Results Sent to Your Doctor',
    title: <FormatMessage id="home.reviews.first.title" />,
    desc: <FormatMessage id="home.reviews.first.desc" />,
  },
  {
    id: 'insigths',
    imgUrl: null, // ImgTimerUrl,
    Icon: QuoteIcon,
    alt: 'Get Your Unique Mental Health Insights',
    title: <FormatMessage id="home.reviews.second.title" />,
    desc: <FormatMessage id="home.reviews.second.desc" />,
  },
];

type Props = {};

const Reviews: React.FC<Props> = () => (
  <div className="reviews">
    <div className="container">
      <h2 className="title">
        <FormatMessage id="home.reviews.title" />
      </h2>
      <div className="review__list">
        {
          REVIEW_LIST.map((item) => (
            <div className="review__item" key={item.id}>
              <div className="review__icon">
                {
                  item.imgUrl
                    ? <img src={item.imgUrl} alt={item.alt} />
                    : <img alt={item.alt} src={item.Icon} />
                }
              </div>
              <h3 className="review__title">{item.title}</h3>
              <p className="review__desc">{item.desc}</p>
            </div>
          ))
        }
      </div>
    </div>
  </div>
);

export default Reviews;
