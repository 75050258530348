// import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const Root = styled('div')((/* { theme } */) => ({
  width: '100%',

  '&>.react-tel-input': {
    width: '100%',
    marginTop: '15px',

    '&>input': {
      fontFamily: 'Manrope',
      padding: '5px 14px 5px 58px',
      width: '100%',
      borderRadius: '20px',
    },
  },
}));

interface PhoneNumberInputProps extends Omit<PhoneInputProps, 'onChange'> {
  /**
     * Lower case country codes
     */
  countries: string[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange:
  (
    e: React.ChangeEvent<HTMLInputElement>,
    phoneNumber: string,
    country: CountryData | {}
  ) => void;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  countries,
  onChange,
  ...otherProps
}) => (
  <Root>
    <PhoneInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      country={countries[0]}
      countryCodeEditable={false}
      onlyCountries={countries}
      onChange={(phoneNumber: string, country: string, e: any) => {
        // country change synthetic event are released before they get to handler
        if (!(e instanceof Event || e?.nativeEvent)) {
          return;
        }

        onChange(e, phoneNumber, country);
      }}
      specialLabel=""
    />
  </Root>
);
export default PhoneNumberInput;
