import React from 'react';
import './style.scss';

const VoiceTool = () => (
  <div className="voice-tool">
    <div id="eh-voice-tool-container" />
  </div>
);

export default VoiceTool;
