import { Reducer } from '@reduxjs/toolkit';
import {
  NAV_TO,
} from '../types/nav';
import NavScreen from '../../enums/navScreen';

// Define a type for the slice state
export interface NavState {
  current: NavScreen;
}

// Define the initial state using that type
const initialState: NavState = {
  current: NavScreen.login,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const authReducer: Reducer<NavState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NAV_TO:
      return {
        ...state,
        current: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
