import React from 'react';
import { FormatMessage } from '../../i18n';

type Props = {
  onGetStarted: () => void;
};

const Footer: React.FC<Props> = ({ onGetStarted }) => (
  <div className="footer">
    <div className="footer__content container">
      <h2 className="title">
        <FormatMessage id="home.footer.title" />
      </h2>
      <a className="button" href="#get-started" target="_self" onClick={onGetStarted}>
        <FormatMessage id="home.header.button.title" />
      </a>
    </div>
  </div>
);

export default Footer;
