// datadogRumUtil.ts
import { datadogRum } from '@datadog/browser-rum';
import logger from './logger';
import { DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN } from '../constants';

const initializeDatadogRum = () => {
  const allowedEnvironments = ['prod2'];
  const currentEnvironment = process.env.REACT_APP_ENV;

  if (!currentEnvironment || !allowedEnvironments.includes(currentEnvironment.toLowerCase())) {
    logger.log(`Datadog RUM initialization skipped. Invalid environment: ${currentEnvironment}`);
    return;
  }

  if (!DATADOG_APPLICATION_ID || !DATADOG_CLIENT_TOKEN) {
    logger.log('Missing datadog applicationId or clientToken in environment variables.');
    return;
  }

  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    service: 'missionmamas',
    env: 'prod',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
};

export default initializeDatadogRum;
