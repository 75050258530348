import loginTheme from './login';
import registerUserTheme from './registerUser';
import verifyCodeTheme from './verifyCode';
import forgotPasswordTheme from './forgotPassword';
import consentTheme from './consent';
import mainMenuTheme from './mainMenu';
// import navTheme, { Theme as NavTheme } from './nav';

export const theme = {
  login: loginTheme,
  registerUser: registerUserTheme,
  verifyCode: verifyCodeTheme,
  forgotPassword: forgotPasswordTheme,
  consent: consentTheme,
  mainMenu: mainMenuTheme,
  // nav: navTheme,
};

export type ScreensTheme = typeof theme;

export default theme;
