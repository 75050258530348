import {
  FormHelperText,
  styled as muiStyled,
} from '@mui/material';

export default muiStyled(FormHelperText)(({ theme }) => ({
  '&': {
    fontFamily: 'Manrope',
    fontSize: '14px',
    left: 0,
    bottom: '-0.85rem',
    margin: 2,
    color: theme.palette.error.main,
    backgroundColor: '#A90C0C',
    padding: '2px',
    width: 'fit-content',
  },
}));
