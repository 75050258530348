import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import Home from '../Home';
import { Language } from '../../components/LangList';
import { AppConfig, DEF_LANGUAGE } from '../../constants';
import { translations, TranslationProvider } from '../../i18n';
import './style.css';
import { useAppDispatch } from '../../store/hooks';
import * as auth from '../../store/actions/auth';

// TODO - read language from browser setting rather than hard-coding:

const App = () => {
  const [searchParams] = useSearchParams();
  const selectedLang = (searchParams.get('lang') || DEF_LANGUAGE) as Language;
  const dispatch = useAppDispatch();

  const onIdle = () => {
    dispatch(auth.logout());
  };

  useIdleTimer({
    onIdle,
    timeout: AppConfig.idleTimeout,
  });

  return (
    <div className="App" role="main">
      <TranslationProvider language={selectedLang} translations={translations}>
        <Home />
      </TranslationProvider>
    </div>
  );
};

export default App;
