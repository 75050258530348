import type { SxProps, Theme as MuiTheme } from '@mui/material';
import { COMPONENT_SPACING, PALETTE } from './common';

export type Theme = {
  root: SxProps<MuiTheme>;
  nav: {
    root: SxProps<MuiTheme>;
    ctaBack: {
      root: SxProps<MuiTheme>;
      icon: SxProps<MuiTheme>;
      text: SxProps<MuiTheme>;
    };
    ctaHome: {
      root: SxProps<MuiTheme>;
      text: SxProps<MuiTheme>;
    };
    ctaLogout: {
      root: SxProps<MuiTheme>;
      text: SxProps<MuiTheme>;
    }
  };
  content: {
    root: SxProps<MuiTheme>;
  };
};

const theme: Theme = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    background: PALETTE.secondary_light,
  },
  nav: {
    root: {
      marginBottom: COMPONENT_SPACING,
      display: 'flex',
    },
    ctaBack: {
      root: {
        textDecoration: 'underline',
        color: PALETTE.secondary_dark,
      },
      icon: {
        marginRight: '1rem',
      },
      text: {
        fontFamily: 'Manrope',
      },
    },
    ctaHome: {
      root: {
        textDecoration: 'underline',
        color: PALETTE.primary,
      },
      text: {
        fontFamily: 'Manrope',
      },
    },
    ctaLogout: {
      root: {
        textDecoration: 'underline',
        marginLeft: 'auto',
      },
      text: {
        fontFamily: 'Manrope',
      },
    },
  },
  content: {
    root: {},
  },
};

export default theme;
