import { initializeApp } from 'firebase/app';
import 'firebase/analytics';
import 'firebase/functions';
import { getAuth } from 'firebase/auth';

let firebaseConfig = {};

if (process.env.REACT_APP_ENV === 'dev') {
  firebaseConfig = {
    apiKey: 'AIzaSyBU5pw8Tl2WEAKyT_tG-4ZEamHYKyJ3bm4',
    authDomain: 'rising-higher-dev.firebaseapp.com',
    databaseURL: 'https://rising-higher-dev.firebaseio.com',
    projectId: 'rising-higher-dev',
    storageBucket: 'rising-higher-dev.appspot.com',
    messagingSenderId: '889370886587',
    appId: '1:889370886587:web:1e1831673dc082aae2c49e',
    measurementId: 'G-VTVH252ETC',
  };
} else if (process.env.REACT_APP_ENV === 'qa') {
  firebaseConfig = {
    apiKey: 'AIzaSyDRMiM1_i_QhlwoR08Zez0b-cnCyr-EoP0',
    authDomain: 'rising-higher-qa.firebaseapp.com',
    databaseURL: 'https://rising-higher-qa.firebaseio.com',
    projectId: 'rising-higher-qa',
    storageBucket: 'rising-higher-qa.appspot.com',
    messagingSenderId: '922833795723',
    appId: '1:922833795723:web:7c7671fb7086ce58a8328e',
    measurementId: 'G-58MSE2N3HL',
  };
} else if (process.env.REACT_APP_ENV === 'prod2') {
  firebaseConfig = {
    apiKey: 'AIzaSyD1gNp81DH44sGre1Cm7dFyE0XXvDWpWiQ',
    authDomain: 'eh-prod-2.firebaseapp.com',
    projectId: 'eh-prod-2',
    storageBucket: 'eh-prod-2.appspot.com',
    messagingSenderId: '418019787664',
    appId: '1:418019787664:web:a32c2d8ee6c6b27e53e915',
    measurementId: 'G-W550V9WQYG',
  };
}
// Initialize firebase app based on the environment
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
