import React, { useEffect } from 'react';
import classnames from 'classnames';
import { Language } from '../../components/LangList';
import Footer from './Footer';
import Header from './Header';
import Instructions from './Instructions';
import Reviews from './Reviews';
import MediaBox, { VoiceToolState } from './MediaBox';
import { useQueryParams, useDeviceTypeBySize } from '../../hooks';
import {
  DEF_LANGUAGE, PARENT_PARTNER_ID, VOICE_TOOL_PARTNER_ID, WELLNESS_PARTNER_ID,
} from '../../constants';
import './style.scss';
import NavScreens from '../NavScreens';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import NavScreen from '../../enums/navScreen';
import { NAV_TO } from '../../store/types/nav';

const Home = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const { lang: selectedLang = DEF_LANGUAGE } = useQueryParams();
  // const selectedLang = useMemo(() => searchParams.get('lang') as Language, [searchParams]);
  // const [error, setError] = useState<boolean>(false);
  const currentScreen = useAppSelector((state) => state.nav.current);
  const [voiceToolState, setVoiceToolState] = React.useState<VoiceToolState>(null);
  const dispatch = useAppDispatch();

  const deviceType = useDeviceTypeBySize();

  // callback to handle start event
  const getStartedHandler = React.useCallback(() => {
    setVoiceToolState('started');
    document.body.classList.add('voice-tool-started');
  }, [voiceToolState]);

  useEffect(() => {
    if (voiceToolState === 'finished') {
      dispatch({
        type: NAV_TO,
        payload: NavScreen.mainMenu,
      });
    }
  }, [voiceToolState]);

  // const selectLangHandler = useCallback((lang: Language) => {
  //   setSearchParams({ lang });
  // }, [setSearchParams]);

  // callback to handle setting language
  // TODO - pass param
  /**
    const setLangHandler = React.useCallback(() => {
        alert('setting lang');
    }, []);
    const setLangHandlerEn = React.useCallback(() => {
        alert('setting lang to en');
    }, []);
    class Square extends React.Component {
        render() {
          return (
            alert('setting lang to ' + this.props.value)
          );
        }
      }
    class Lang extends React.Component {
        set(locale) {
            return <Square value={locale} />
        }
    }
    const setLangHandlerJa = React.useCallback(() => {
        const widget = document.getElementById("eh-voice-tool-container")?.firstElementChild;
        alert('setting lang to ja');
    }, []);
    */

  // let widget;
  // if (currentScreen === NavScreen.voiceWidget) {
  //   widget = <MediaBox onStateChanged={setVoiceToolState} />;
  // } else if ()

  return (
    <div className={classnames('home', `device-${deviceType}`, voiceToolState && `voice-tool-${voiceToolState}`)}>
      <Header
        onGetStarted={getStartedHandler}
        // onSelectLang={selectLangHandler}
        selectedLang={selectedLang as Language}
      />
      <Instructions onGetStarted={getStartedHandler} />
      <div className="nav-screens" id="get-started">
        {
          // eslint-disable-next-line no-nested-ternary
          currentScreen === NavScreen.voiceWidget
            ? (
              <MediaBox
                onStateChanged={setVoiceToolState}
                partnerId={VOICE_TOOL_PARTNER_ID}
                parentPartnerId={PARENT_PARTNER_ID}
              />
            )
            : currentScreen === NavScreen.wellnessWidget
              ? (
                <MediaBox
                  onStateChanged={setVoiceToolState}
                  partnerId={WELLNESS_PARTNER_ID}
                  parentPartnerId={PARENT_PARTNER_ID}
                />
              )
              : <NavScreens />
        }
      </div>
      <Reviews />
      <Footer onGetStarted={getStartedHandler} />
    </div>
  );
};

export default Home;
