import React from 'react';
import {
  Box, Button, useTheme,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import Screen from '../../components/Screen';
import { FormatMessage } from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import * as auth from '../../store/actions/auth';
import {
  sendCodeSchema,
  SendCodeType,
  changePasswordSchema,
  ChangePasswordType,
} from '../../schemas/forgotPassword';
import FormInput from '../../components/Form/Input';
import logger from '../../utils/logger';
import ErrorAlert from '../../components/ErrorAlert';
import Loading from '../../components/Loading';

const ForgotPassword = () => {
  const forgotPasswordState = useAppSelector((state) => state.auth.forgotPassword);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {
    handleSubmit: handleSendCodeSubmit,
    formState: { errors: formSendCodeErrors },
    register: formSendCodeRegister,
  } = useForm<SendCodeType>({
    resolver: yupResolver(sendCodeSchema),
  });
  const {
    handleSubmit: handleChangePasswordSubmit,
    formState: { errors: formChangePasswordErrors },
    register: formChangePasswordRegister,
  } = useForm<ChangePasswordType>({
    resolver: yupResolver(changePasswordSchema),
  });

  const onSendCodeSubmit: SubmitHandler<SendCodeType> = (data) => {
    dispatch(auth.forgotPasswordSendCode({ email: data.email }));
  };

  const onChangePasswordSubmit: SubmitHandler<ChangePasswordType> = (data) => {
    if (forgotPasswordState.sendCode.data.email) {
      dispatch(auth.forgotPasswordChangePassword({
        ...data,
        email: forgotPasswordState.sendCode.data.email,
      }));
    }
  };

  logger.debug({ formSendCodeErrors, formChangePasswordErrors });

  return (
    <Screen className="forgot-password" gaName="verify-code" canNavBack>
      <Box sx={theme.screens.forgotPassword.title}>
        <FormatMessage id="forgotPassword.title" />
      </Box>
      <Box sx={theme.screens.forgotPassword.description}>
        <FormatMessage id="forgotPassword.description" />
      </Box>
      <Box component="form" sx={theme.screens.forgotPassword.form} onSubmit={handleSendCodeSubmit(onSendCodeSubmit)}>
        <FormInput
          name="Email Address"
          inputProps={{
            ...formSendCodeRegister('email'),
            sx: theme.screens.forgotPassword.fields.inputCode,
            autoComplete: 'off',
          }}
          error={formSendCodeErrors.email?.message}
          placeholderId="forgotPassword.emailPlaceholder"
        />
        <Button
          variant="contained"
          sx={theme.screens.forgotPassword.ctaSendCode}
          type="submit"
          disabled={(
            forgotPasswordState.sendCode.loading || forgotPasswordState.changePassword.loading
          )}
        >
          {forgotPasswordState.sendCode.loading && (
            <Box style={{ display: 'flex' }}>
              <Loading />
              <FormatMessage id="forgotPassword.ctaSendingCode" />
            </Box>
          )}
          {!forgotPasswordState.sendCode?.data?.success
            && !forgotPasswordState.sendCode.loading && (
              <FormatMessage id="forgotPassword.ctaSendCode" />
          )}
          {forgotPasswordState.sendCode?.data?.success && (
            <FormatMessage id="forgotPassword.ctaResendCode" />
          )}
        </Button>
      </Box>
      {forgotPasswordState.sendCode?.data?.success === true && (
        <Box component="form" sx={theme.screens.forgotPassword.form} onSubmit={handleChangePasswordSubmit(onChangePasswordSubmit)}>
          <FormInput
            name="Enter Code Sent to Phone"
            inputProps={{
              ...formChangePasswordRegister('code'),
              sx: theme.screens.forgotPassword.fields.inputCode,
              autoComplete: 'off',
            }}
            error={formChangePasswordErrors.code?.message}
            placeholderId="forgotPassword.codePlaceholder"
          />
          <Box sx={theme.screens.forgotPassword.fields.inputCodeInfo}>
            <FormatMessage id="forgotPassword.contactSupport" />
          </Box>
          <FormInput
            name="Enter Password"
            inputProps={{
              ...formChangePasswordRegister('password'),
              sx: theme.screens.forgotPassword.fields.inputPassword,
              type: 'password',
              autoComplete: 'off',
            }}
            error={formChangePasswordErrors.password?.message && 'Invalid password'}
            placeholderId="forgotPassword.passwordPlaceholder"
            tooltipId="forgotPassword.passwordTooltip"
          />
          <FormInput
            name="Retype Password"
            inputProps={{
              ...formChangePasswordRegister('confirmPassword'),
              sx: theme.screens.forgotPassword.fields.inputConfirmPassword,
              type: 'password',
              autoComplete: 'off',
            }}
            error={formChangePasswordErrors.confirmPassword?.message && 'Invalid password'}
            placeholderId="forgotPassword.confirmPasswordPlaceholder"
          />
          {forgotPasswordState?.sendCode?.error
            && <ErrorAlert message={forgotPasswordState?.sendCode?.error} />}
          {forgotPasswordState?.changePassword?.error
            && <ErrorAlert message={forgotPasswordState?.changePassword.error} />}
          <Button
            variant="contained"
            sx={theme.screens.forgotPassword.ctaSubmit}
            type="submit"
            disabled={(
              forgotPasswordState.sendCode.loading || forgotPasswordState.changePassword.loading
            )}
          >
            {forgotPasswordState.changePassword.loading ? (
              <Box style={{ display: 'flex' }}>
                <Loading />
                <FormatMessage id="forgotPassword.ctaChangingPassword" />
              </Box>
            )
              : (
                <FormatMessage id="forgotPassword.ctaSubmit" />
              )}
          </Button>
        </Box>
      )}
    </Screen>
  );
};

export default ForgotPassword;
