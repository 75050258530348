import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
//-----------------------------------------------------------------------------------------
/**
 * We have two different memories we can use in the browser: localStorage and sessionStorage
 * The only difference is that sessionStorage gets cleared when the user closes the tab.
 * This is the behaviour needed for security reasons.
 */
// In order to import the localStorage memory use the following:
// import storage from 'redux-persist/lib/storage';

// In order to import the sessionStorage memory use the following:
import storageSession from 'redux-persist/es/storage/session';
//-----------------------------------------------------------------------------------------
import { persistReducer, persistStore } from 'redux-persist';

import reducers from './reducers';

const persistConfig = {
  key: 'root',
  // In order to use the sessionStorage memory use the following:
  storage: storageSession,
  // In order to use the localStorage memory use the following:
  // storage
};
const persistedReducer = persistReducer(persistConfig, reducers);

// ...

// const middlewares = [];

// if (process.env.NODE_ENV === 'development') {
// middlewares.push(logger);
// }

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  // middleware: middlewares,
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = (
  ThunkAction<ReturnType, RootState, unknown, AnyAction>
);

export default store;
