export type Clinician = {
  id: string;
  name: string;
  email: string;
};

export type WithLoadingStatus<T> = {
  loading: boolean;
  data: T;
  error?: string | null;
  info?: string | null
};

export const createWithLoadingStatus = <T>(data: T): WithLoadingStatus<T> => ({
  loading: false,
  data,
});
